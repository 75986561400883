<template>
    <v-container fluid>
        <v-row>
            <v-col cols="2"> <analyticsDrawer /> </v-col>
            <v-col>
                <router-view></router-view>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import analyticsDrawer from '@/components/drawers/drawer-analytics';

export default {
    name: 'settings-layout',
    data: () => ({}),
    components: { analyticsDrawer },
    created() {},
    computed: {},
    methods: {},
};
</script>

<style></style>
