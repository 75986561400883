<template>
    <app-drawer
        :menus="menus"
        :allow-mini="false"
        :clipped="false"
        :dark="false"
        :floating="true"
        color="transparent"
        :app="false"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import appDrawer from './drawer';

export default {
    name: 'drawer-settings',
    data: () => ({
        mini: true,
    }),
    components: {
        appDrawer,
    },
    computed: {
        ...mapGetters(['profile']),
        allMenus() {
            return [
                {
                    to: 'analytics-times',
                    label: 'analytics.timesByUser',
                    icon: 'mdi-timeline-clock',
                    name: 'analytics-times',
                    group: 'analytics.times',
                    position: 'top',
                },
            ];
        },
        menus() {
            return this.allMenus;
        },
    },
};
</script>
